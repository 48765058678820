html {
	width:100%;
    height  : 100%;
    overflow: hidden;
    position: relative;
	margin: 0;
}

body {
	margin: 0;
	overflow-y: hidden;
	overflow-x: hidden;
	background-image: url(./images/lgi01a201411091900.jpg);
	  width : 100%;
	height : 100%;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
  }

.svg-container {
	height: 100%;
	width: 100%;
}

svg {
  stroke: transparent;
  stroke-width: 0px;
}

.heavy {
	font: bold 28px sans-serif;
	fill:whitesmoke
  }

.small {
	font: italic 16px sans-serif;
	fill:whitesmoke
  }

.medium {
	font: italic 17px sans-serif;
	fill:whitesmoke
  }

.medium2 {
	font: italic 19px sans-serif;
	fill:whitesmoke
  }

.small2 {
	font: italic bold 16px sans-serif;
	fill:#523438
  }

@font-face {
	font-family: 'ArialRoundedMTBold';
	src: local('ArialRoundedMTBold'), url(./fonts/arialroundedmtbold.ttf) format('ttf');
  }

@media (min-aspect-ratio: 8/5) { 

	.svg-content {
		display: inline-block;
		position: absolute;
		top: 0;
		left: 50%;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		background-color : #291719;
	}

    #container {
		display: block;
		margin: 0 auto;
		height: 100%;
	}

}

@media (max-aspect-ratio: 8/5) { 

	.svg-content {
		display: inline-block;
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		background-color : #291719;
	}

    #container {
		display: block;
		margin: auto 0;
		width: 100%;
	}

}